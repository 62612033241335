
import { defineComponent } from "vue";
import * as moment from "moment";

export default defineComponent({
  name: "message-out",
  props: {
    name: String,
    image: String,
    time: String,
    text: String,
    header: String,
    files: Object as any,
  },
  setup() {
    moment.default.locale("fr");

    

    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD hh:mm:ss").fromNow();
    };

    const lengthChar = (str) => {
      return str.length;
    };

    return {
      formatDateAgo,
      lengthChar,
    };
  },
});


